.top-left-items {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  span {
    margin-bottom: 0;
    line-height: 1.5;
    color: #667080;
    &:first-child {
      font-size: 20px;
      font-family: Kanit-Medium;
    }
  }
}

.ant-layout-header {
  height: 100px;
}
