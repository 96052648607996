.wrap-register-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrap-form.wrap-form-register {
    margin: 0 24px;
    border-radius: 6px;
  }

  .wrap-form {
    max-width: 500px;
    width: 100%;
    padding: 24px;
    background: #ffffff;
  }
  form {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
    .ant-form-item {
      label {
        color: #667080;
      }
    }
    input {
      height: 48px;
      border-radius: 6px;
      &:active,
      &:focus {
        border-color: #d22d29;
        box-shadow: none;
      }
    }
  }
}

.wrap-confirm-btn-otp {
  text-align: center;
  button {
    background: #d22d29;
    border-radius: 6px;
    color: #ffffff;
    height: 40px;
    border: none;
    width: 100%;
  }
}

.wrap-otp-input {
  text-align: center;
  margin-bottom: 40px;
  p {
    &:first-child {
      font-size: 18px;
      margin-bottom: 0;
    }
    span {
      font-family: "Kanit-Medium";
    }
  }
  button {
    border: none;
    box-shadow: none;
    span {
      color: #667080;
      text-decoration: underline;
    }
  }
  > div {
    justify-content: center;
    margin: 40px 0;
  }
  .otp-input-style {
    width: 40px !important;
    border: 1px solid #d9d9d9;
    margin-right: 12px;
    height: 48px;
    border-radius: 6px;
    font-family: "Kanit-Medium";
    &:active,
    &:focus {
      border-color: #d22d29;
      box-shadow: none;
    }
    &:focus-visible {
      border-color: #d22d29 !important;
      outline: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.login-btn {
  background: #d22d29;
  border-radius: 6px;
  color: #ffffff;
  height: 40px;
  border: none;
  &:hover,
  &:focus,
  &:visited {
    background-color: #d22d29;
    color: #ffffff;
  }
}

.wrap-confirm-otp-success {
  text-align: center;
  margin: 40px 0;
  justify-content: center;

  p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .success-icon {
    font-size: 30px;
    color: #52c41a;
  }
}
