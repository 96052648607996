@font-face {
  font-family: Kanit-Medium;
  src: url("../assets/fonts/kanit/Kanit-Medium.ttf");
}

@font-face {
  font-family: Kanit-Bold;
  src: url("../assets/fonts/kanit/Kanit-Bold.ttf");
}

@font-face {
  font-family: Kanit-ExtraBold;
  src: url("../assets/fonts/kanit/Kanit-ExtraBold.ttf");
}

@font-face {
  font-family: Kanit-Regular;
  src: url("../assets/fonts/kanit/Kanit-Regular.ttf");
}

@font-face {
  font-family: Kanit-LightItalic;
  src: url("../assets/fonts/kanit/Kanit-LightItalic.ttf");
}

@font-face {
  font-family: Kanit-Light;
  src: url("../assets/fonts/kanit/Kanit-Light.ttf");
}
